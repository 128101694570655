@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url('assets/ajax-loader.gif') center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top:0;
	
	margin-top:-5px;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;

	background: transparent url(assets/arrows.gif) left top no-repeat;
	
	z-index: 10;
	
	opacity:0.6;
	background-size: auto 18px;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
}

.slick-prev
{
    right: 30px;
}

.slick-next
{
    right:0px;
	background-position:right top;
}


.slick-next {transition: all .3s; animation: jumpInfinite 1.5s infinite;}

@keyframes jumpInfinite {
  0% {
    right: 6px;
  }
  50% {
    right: -6px;
  }
  100% {
    right: 6px;
  }
}