.slick-prev, .slick-next {top:-25px;}

.slick-next:before, [dir=rtl] .slick-prev:before {
    content: '';
}

.slick-prev:before {
    content: '';
}

.slick-prev:hover, .slick-prev:focus {
	background: transparent url(assets/arrows.gif) left top no-repeat;
    background-size: auto 18px;
}


.slick-next:hover, .slick-next:focus {
    background: transparent url(assets/arrows.gif) right top no-repeat;
    background-size: auto 18px;
}

.slick-prev {
    right: 30px !important;
    left: auto;
}