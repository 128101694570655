/* RESET CSS */
/* RESET CSS */
/* RESET CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.img-responsive {
	max-width: 100%; 
	/* display:block; */
	height: auto;
}
/* END RESET CSS */
/* END RESET CSS */
/* END RESET CSS */

.alert{
  /* padding:15px;
  margin-bottom:20px; */
  border:1px solid transparent;
  border-radius:4px;
}
.alert > p,.alert > ul{margin-bottom:0;}
.alert > p + p{margin-top:5px;}
.alert-success{color:#3c763d;background-color:#dff0d8;border-color:#d6e9c6;}
.alert-info{color:#31708f;background-color:#d9edf7;border-color:#bce8f1;}
.alert-warning{color:#8a6d3b;background-color:#fcf8e3;border-color:#faebcc;}
.alert-danger{color:#a94442;background-color:#f2dede;border-color:#ebccd1;}

.alert-overlay {}
.alert-close {}
.alert-content {}

.alert-close {position:absolute;top:0;right:0;padding:10px;color:#fff;background:#00162d;border:0;border-radius: 4px;margin-top: -10px;margin-right: -10px;transition:all .3s .12s;cursor:pointer;z-index: 100;}
.alert-close .icon {display:inline-block;width:16px;height:16px;vertical-align:middle;fill:currentcolor;}
.alert-close:hover{background:#004f9f}
.alert {position:fixed;z-index:10000;top:0;left:0;width:100%;height:100%}

.alert-overlay {position:fixed;z-index:10;top:0;left:0;width:100%;height:100%;background:hsla(0,0%,0%,0.9);transition:all 0 linear 0.3s}

.alert-content{position:fixed;z-index:9999;top:10vh;left: 50%;width:80%;max-width:800px; box-sizing:border-box;box-sizing:border-box;}

.alert-center {position: relative; left: -50%; border-radius:20px; box-sizing:border-box; padding:30px 40px; background:#16204c; box-shadow:0 0 1.5em hsla(0,0%,0%,0.35); font-size:18px; color:#ffffff;}





.nowrap {white-space:nowrap;}
.hidden {position:absolute; left:-9999px; text-indent:-9999px; overflow:hidden; width:0px; height:0px;}
.none {display:none;}
.clear {clear:both;}
.strong, strong, .bold {font-weight:bold;}
.uppercase {text-transform:uppercase;}

body, html {width:100%; height:100%; margin:0; padding:0;}

body {font-family: 'Raleway', sans-serif; font-size:13px; line-height:23px; color:#000000; text-align:left;
   background-image:url("assets/bg.png");
   background-color:#ffffff; height: auto;}

a {color:#0691f4; text-decoration:none;}

.cc-message {font-size:13px !important; line-height:16px !important;}
.cc-btn {min-width:80px !important; padding: 0px 16px !important;}

iframe {display:block;}
big {font-size:16px; font-weight:600;}

p {margin-top:0; font-size:14px; line-height:22px;}
p+p {margin-top:16px;}

p.light {opacity: 0.6;}
p.big {font-size:16px;}

.transition, a, .btn, .navbar-main li a, .navbar-main ul, .dropdown-parent, .dropdown-child {-webkit-transition: all 0.65s; -moz-transition: all 0.65s; -o-transition: all 0.65s; transition: all 0.65s;}

input[type="date"], input[type="time"], input[type="text"], select, input[type="password"], input[type="search"], textarea, input[type="email"] {width:100%; box-sizing:border-box; padding:10px 14px; border:1px solid #757575; border-radius:6px; margin-top:3px; margin-bottom:8px; font-family: 'Raleway', sans-serif; font-size:14px; background-color:#ffffff; color:#a1a1b2;}

.container-fluid {padding-right: 2rem; padding-left: 2rem;}
.container, .container-fluid {margin-right: auto; margin-left: auto;}
.wrap {box-sizing: border-box; max-width: 1300px; /* max-width:1068px; */ margin: 0 auto;}
.wrap.larger {max-width:1472px;}

.lightblue {color:#0694f8;}

.header {background-color:#16204c; background-position:center center; background-repeat:no-repeat; box-sizing:border-box; padding:16px 0;   position: sticky; top:0; z-index:20000;}
.logo img {max-width: 160px; display:block;}

.hero {background-color:#F5F5F5; 
  background-image:url('assets/hero-bg.png');
   background-repeat:no-repeat; background-position:left bottom; padding:50px 0 66px 0;}
.hero-img {width:100%; /* height: 360px; */}
.hero form {margin:20px 0 10px 0; max-width:600px; width:420px;}
.hero form .btn {margin-left:-5px;}
.newsletter-hero h2 {font-size: 2.0125em;}

.newsletter-hero {background-color:rgba(255,255,255,0.7); /* background-image:url(newsletterbg.png); background-repeat:no-repeat; background-position:right bottom; background-size:auto 80%; */ padding:20px 30px; border-radius:8px; width: fit-content; border:1px solid #d3d3d3; margin-top:60px;}

.img-round {border-radius:8px;}

/* MENU */
.navbar-main {position:relative; z-index:3; display:inline-block;}
.navbar-main li {display:inline-block; position:relative;}
.navbar-main li a {display:block; font-size:15px; height:46px; line-height:46px; padding:0 12px; font-weight:700; color:#ffffff; border-radius:6px;}
.navbar-main > li > a:hover, .navbar-main > li.active > a {color:#ffffff; background:#37bdf2;}

/* SUBMENU */
.navbar-main ul {display:none; position:absolute; top:100%; left:0px; background:#37bdf2; padding:10px 0; z-index:100; text-align:left; border-radius:6px; min-width:200px;}
.navbar-main ul li {display:block; width:auto; height:auto; padding:0;}
.navbar-main ul li a {color:#ffffff; display:block; font-size:13px; line-height:16px; height:auto; font-weight:500; padding:10px 18px; margin:0 8px; text-transform:none;}
.navbar-main ul li a:hover, .navbar-main ul > li.active > a {background:#2f4984; border-radius:6px;}
.navbar-main li:hover > ul {display:block}

.nav-toggle {display:none;}

.navbar-main li.dropdown:before {position:absolute; right:4px; top:12px; display: inline-block; font-style: normal; font-variant: normal; text-rendering: auto; -webkit-font-smoothing: antialiased; font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f0d7"; color:#ffffff;}

.singup {background-color:#F5F5F5; padding:50px 0 60px 0;}

.gray-bg {background-color:#F5F5F5; padding:30px 0 40px 0;}
.gray-bg form {margin:30px auto 20px auto; max-width:500px; }
.gray-bg form .btn {margin-left:-5px;}

/* Buttons */


.btn {cursor:pointer; font-size:15px; color:#ffffff; box-sizing:border-box; padding:10px 22px; border-radius:6px; margin-top:3px; margin-bottom:8px; font-family: 'Raleway', sans-serif; font-weight:500; border:0;}
.btn-large {display:block; margin:20px auto; width:fit-content; padding:25px 45px; text-align:center; color:#ffffff; background:#1a8ef1; font-size:24px; text-transform:uppercase; font-weight:900;}
.btn.bold {font-weight:bold;}

.btn:disabled {opacity:0.15; cursor:not-allowed;}

.btn-light {background:#ffffff; color:#37bdf2; border:1px solid #37bdf2;}
.btn-primary {background:#37bdf2;}
.btn-secondary {background:#1a8ef1;}
.btn-orange {background: rgb(251, 109, 24);}

.btn-light:hover {border:1px solid #1a8ef1; color:#1a8ef1;}
.btn-primary:hover {background:#1a8ef1;}
.btn-secondary:hover {background:#37bdf2;}
.btn-orange:hover {background: rgb(218,79,21);}


.btn-cancel {background:#f9322d;}
.btn-small {padding:6px 12px;}

.header .btn {margin-left:12px;}

.btn-xs {padding:4px 8px; font-size:12px;}


.btns {margin-top:20px;}
.btns a {font-size:14px; color:#ffffff; box-sizing:border-box; padding: 6px 14px; border-radius:6px; margin-top:3px; margin-bottom:8px; font-family: 'Raleway', sans-serif; font-weight:500; border:0; background:#ffffff; color:#37bdf2; border:1px solid #37bdf2; display:block;}
.btns a.active {background:#37bdf2; color:#ffffff;}

.btns a:hover {/* border:1px solid #1a8ef1; color:#1a8ef1; */ background:#37bdf2; color:#ffffff;}
.btns a.active:hover {background:#1a8ef1;}

/*
.btns.btns-primary a {background:#37bdf2; color:#ffffff; border:1px solid #37bdf2;}
.btns.btns-primary a:hover, .btns.btns-primary a.active {background:#16204c; color:#ffffff; border:1px solid #16204c;}
*/

.recommended-sites {margin-top:20px;}
.recommended-sites li {margin-bottom:6px;}
.recommended-sites a {font-size:14px; font-weight:bold;}
.recommended-sites a span {border:1px solid #37bdf2; display:inline-block; padding:0px 4px; font-size:11px; border-radius:6px; min-width: 16px; margin-right:4px; text-align:center;}

.txt-gold {
	background-image: linear-gradient(
	to right,
	#462523 0,
       	#cb9b51 22%, 
	#f6e27a 45%,
	#f6f2c0 50%,
	#f6e27a 55%,
	#cb9b51 78%,
	#462523 100%
	);
   color:transparent;
   -webkit-background-clip:text;
}

.bg-gold {color:#ffffff; background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);}

#pagination {
  margin: 0;
  padding: 0;
  text-align: right
}
#pagination li {
  display: inline
}
#pagination li a {
  display: inline-block;
  text-decoration: none;
  padding: 5px 10px;
  color: #000
}

/* Active and Hoverable Pagination */
#pagination li a {
  border-radius: 6px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s
    
}
#pagination li a.active {
  background-color: #16204c;
  color: #fff
}
#pagination li a:hover:not(.active) {
  background-color: #ddd;
} 


.belt-promo {background:#37bdf2; padding:10px 0; text-align:center;}
.belt-promo p {color:#ffffff; font-size:16px;}
.belt-top {background:#f2a517;}
.belt-middle {background:#1a8ef1;}


h1, .h1 {font-size:3.2em; line-height:1.2; /* font-weight:normal; */ color:#16204c;}
h1 strong, .h1 strong {font-weight:900;}

h2 {font-size:2.8125em; line-height:1.2; font-weight:normal; color:#16204c;}
h2 strong {font-weight:900;}

h2.alt {font-size: 30px;}
h2.alt strong {font-weight:900;}
h2.alt u {color:#37bdf2; font-style:normal; text-decoration:none;}
h2.alt i {color:#1a8ef1; font-style:normal;}

h3 {font-size:23px; line-height:30px; font-weight:normal;}
h3 strong {font-weight:900;}

h4 {font-size:18px; font-weight:500; color:#000000; line-height:24px;}
h4 strong {font-weight:900;}

h5 {font-size:16px; font-weight:normal; color:#000000; line-height:20px;}
h5 strong {font-weight:900;}



h1, .h1 {width: fit-content; margin-top: 25px; margin-bottom:16px; font-weight:700;
  /* background: linear-gradient(219deg, 
    #16204c 19%, transparent 19%, transparent 20%, #ff6600 20%, #000000 39%, #000000 80%); */
    /* background: linear-gradient(219deg, #16204c 0%, #16204c 50%, #f60 50%, #f60 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; */
}
h1 span, .h1 span {position: relative; font-weight:700; color:#16204c;}
h1 span:before, .h1 span span:before {content: ''; display: block; position: absolute; /* top: calc(50% + 2px); */ bottom:-3px; left: -10px; width: calc(100% + 20px);  height: 25%; transform: translateY(-50%); z-index: -1; background-color:#37bdf2;}


h1.h1Center {margin: 25px auto 20px auto;}


#footer {background:#E6E6E6; padding:30px 0 35px 0; /* color:#ffffff; */}
#footer .col-xs-12 > h5 {margin-bottom:20px; font-weight:bold;}
#footer .logo img {max-width:100%;}
.copyright a, #footer a:not(.btn) {color:#16204c;}
.navbar-footer li a {font-size:14px; /* color:#ffffff; */}

#footer .btn {min-width: 110px; text-align: center;}

.copyright {font-size:12px; padding:20px 0 25px 0; /* color:#ffffff; */}
.copyright * {font-size:12px;}

ul.styled, ul.styled li {padding: 0; margin: 0; list-style: none;}
ul.styled {margin: 2em 0;}
ul.styled li {margin: 1em; margin-left: 30px;}
ul.styled li:before {content: '\f05d'; font-family: 'FontAwesome'; float: left; margin-left: -1.5em; color: #0074D9;}

.hero ul.styled {margin:10px 0;}
.hero ul li {font-size: 17px; display:inline-block; margin-top:0; margin-bottom:0;}


.flexbox {
	display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-flow: row wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font: 0/0 $font-family; /* for IE10-9 */
}

.flex {
	flex: 1;
}

.d-flex {display:flex;}
.d-ib {display:inline-block !important;}
.d-block {display:block !important;}


/*
.slick-dots {text-align:center; margin-top: -60px; position: relative; z-index: 20;}
.slick-dots li {display:inline-block; margin:0 6px; position:relative; width:10px; height:10px; border:2px solid #eeeeee; border-radius:6px; background:transparent; cursor:pointer;}
.slick-dots li.slick-active {background:#eeeeee;}
.slick-dots li button {text-indent:-9999px; font-size:0; position:absolute; left:0; top:0; right:0; bottom:0; visibility:hidden;}
*/

.pager__item {margin-right:6px;}
.slick-dots li {display:inline-block; margin-right:8px;}
.slick-dots li.slick-active a {color:#ffffff; background:#37bdf2;}
.slick-dots {position: absolute; top: 0; margin-top: -5px; display: block;
-webkit-transform: translate(0, -50%);
-ms-transform: translate(0, -50%);
transform: translate(0, -50%); cursor: pointer;}

.awesome-wrapper {position: relative;}
.awesome-wrapper i {position: absolute; top: 15px; right: 15px; font-size: 15px; color:#cacaca;}

hr {height:1px; background:rgba(189, 189, 189, 0.5); border:0; margin:20px 0;}

.box {position:relative; height:100%; border: 1px solid #bfbfbf; border-radius: 8px;}
.bLabel {position:absolute; left:20px; top:20px; color:#ffffff; padding:8px 14px; border-radius:6px; text-transform:uppercase; font-weight:bold;}
.box h3 {margin:30px 0 20px 0; font-weight:bold;}
.box .btn {margin-top:40px; display:block; width: fit-content;}
.box p {font-size:17px; line-height:24px; min-height: 100px;}
.box .box-padding {padding:0 30px 30px 30px;}


.customModal .icon{display:inline-block;width:16px;height:16px;vertical-align:middle;fill:currentcolor}

.customModal{position:fixed;z-index:10000;top:0;left:0;visibility:hidden;width:100%;height:100%}
.customModal.is-visible{visibility:visible}
.customModal-overlay{position:fixed;z-index:10;top:0;left:0;width:100%;height:100%;background:hsla(0,0%,0%,0.9);visibility:hidden;opacity:0;transition:visibility 0 linear 0.3s,opacity .3s}
.customModal.is-visible .customModal-overlay{opacity:1;visibility:visible;transition-delay:0}
.customModal-wrapper{position:fixed;z-index:9999;top:6vh;left: 50%;width:80%;max-width:800px; box-sizing:border-box;}
.customModal-transition{transition:all .3s .12s;transform:translateY(-10%);opacity:0}
.customModal.is-visible .customModal-transition{transform:translateY(0);opacity:1}
.customModal-close{position:absolute;top:0;right:0;padding:10px;color:#fff;background:#00162d;border:0;border-radius: 4px;margin-top: -10px;margin-right: -10px;transition:all .3s .12s;cursor:pointer;z-index: 100;}
.customModal-close:hover{background:#004f9f}
.customModal-content {/* height:98vh; */ box-sizing:border-box;}

.customModal-loader {position:fixed; left:50%; margin-left:-70px; top:50%; margin-top:-70px; z-index: 1000; opacity:0;}
.customModal.is-visible .customModal-loader {opacity:1;}

.customModal-center {position: relative; left: -50%; border-radius:6px; background:#242424; box-shadow:0 0 1.5em hsla(0,0%,0%,0.35);}
.customModal-center .btn {display:inline-block;}
.customModal-center p, .customModal-center h2, .customModal-center h3, .customModal-center h4, .customModal-center h5 {color:#ffffff; font-weight: 600;}

@media only screen and (max-width: 460px) {
	.customModal-wrapper {width:95%;}
}


.breadcrumbs {width: 100%; box-sizing:border-box; padding: 6px 30px; margin: 1em auto; background-color: #111a40; border-radius:40px;}
.breadcrumbs:after {clear: both; content: ""; display: table;}
.breadcrumbs li {display: inline-block; float: left; margin: 8px 0; font-size:12px;}
.breadcrumbs li:after {display: inline-block; content: '\00bb'; margin: 0 .6em; color: #ffffff;}
.breadcrumbs li:last-of-type:after {
  /* hide separator after the last item */
  display: none;
}
.breadcrumbs li > * {display: inline-block; font-size: 12px; color: #a8a8a8;}
.breadcrumbs li.current > * {color: #0991f4;}
.no-touch .breadcrumbs a:hover {color: #0991f4;}

@media only screen and (min-width: 768px) {
  .breadcrumbs li:after {
    margin: 0 1em;
  }
  .breadcrumbs li > * {
    font-size: 13px;
  }
}



.tooltip {position: relative; font-size:14px; line-height:18px; display:inline-block; margin-left:5px;}
.tooltip i {width: 16px; background: #888; text-align: center; padding: 4px 0; border-radius: 5px; font-size: 9px; color: #fff; opacity:.8;}
.tooltip .tooltiptext {visibility: hidden; width: 220px; background-color: #0d1122; color: #fff; text-align: center; box-sizing:border-box; padding: 10px 18px; border-radius: 6px; top: -5px; left: 180%;  position: absolute; z-index: 1; display:inline-block; font-size:13px;}
.tooltip:hover .tooltiptext {visibility: visible;}

h3 .tooltip {font-size: 17px;}
h3 .tooltip .tooltiptext {font-size:14px;}

.tooltip.left .tooltiptext {right: 180%; left:auto;}

.font-red {color:#E40000;}
.font-green {color:#11CA87;}
.font-white {color:#ffffff;}
.font-orange {color:#ff6600;}
.font-white {color:#ffffff;}

.bg-red {background-color:#E40000;}
.bg-green {background-color:#11CA87;}
.bg-blue {background-color:#37bdf2;}
.bg-dark {background-color:#16204c;}
.bg-gray {background-color:#9C9C9C;}
.bg-orange {background-color:#ff6600;}

.swal2-popup {background:#16204c !important;}
.swal2-popup * {color:#ffffff !important;}
.swal2-confirm, .swal2-confirm:focus, button.swal2-styled, button.swal2-styled:focus, .swal2-confirm:active, button.swal2-styled:active {border:0 !important; outline: none !important;}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {background: rgba(0,0,0,.85) !important;}
.swal2-container {z-index:2160 !important;}

.swal2-confirm.swal2-styled {background: rgb(6,148,248) !important; font-size:14px; color:#ffffff; box-sizing:border-box; padding:12px 22px; border-radius:6px !important; font-family: 'Raleway', sans-serif; font-weight:500;}
.swal2-confirm.swal2-styled:focus {box-shadow:none !important; outline:none !important;}
#swal2-title {font-size:22px;}
#swal2-content {font-size:14px; line-height:20px;}
.swal2-popup.swal2-modal {padding:20px 30px 30px 30px;}

.swal2-cancel.swal2-styled {font-size:14px; color:#ffffff; box-sizing:border-box; padding:12px 22px; border-radius:40px !important; font-family: 'Raleway', sans-serif; font-weight:500;}

/* .pricing-table {overflow: hidden;} */

.pricing-card {position:relative; cursor: pointer; overflow: hidden;}
.pricing-card:hover {}
.pricing-card-header {}
.pricing-card-header h3 {font-size:18px; font-weight:bold; padding:14px 15px; text-align:center; border-top-left-radius:8px; border-top-right-radius:8px; background:#D9D9D9;}
.pricing-card-header h3 strong {font-weight:900;}
.pricing-card-header .price {border-left:1px solid #C6C6C6; border-right:1px solid #C6C6C6; font-size:32px; font-weight:900; text-align:center; padding: 30px 0 10px 0;}
.pricing-card-header .price em {font-style:normal; font-size:14px; font-weight:normal;}
.pricing-card-header p {border-left:1px solid #C6C6C6; border-right:1px solid #C6C6C6; text-align:center; padding:15px 15px 30px 15px; min-height: 70px;}

.order-btn {font-size:15px; font-weight:900; color:#ffffff; background:#9C9C9C; padding:14px 15px; text-transform:uppercase; display:block; text-align:center; border-bottom-left-radius:8px; border-bottom-right-radius:8px;}

.pricing-card-features {border-left:1px solid #C6C6C6; border-right:1px solid #C6C6C6; border-radius:8px; text-align:center; margin-top:20px;}
.pricing-card-features ul {}
.pricing-card-features ul li {font-size: 16px; font-weight:bold; padding: 10px;}

.pricing-card-features ul li:first-child {border-top-left-radius:8px; border-top-right-radius:8px;}
.pricing-card-features ul li:nth-child(odd) {background: #ececec;}
.pricing-card-features ul li:nth-child(even) {background: #ffffff;}

/*
.pricing-card-features .fa-times-circle {color:#E40000; font-size: 22px;}
.pricing-card-features .fa-check-circle {color:#11CA87; font-size: 22px;}
*/

.fa-times-circle {color:#E40000; font-size: 22px;}
.fa-check-circle {color:#11CA87; font-size: 22px;}
.fa-exclamation-triangle {color:#ff6600; font-size: 22px;}

.slick-list {padding-top:20px !important; padding-bottom:20px !important;}

.pricing-card.pc0 {margin-top: 20px; overflow:visible;}
.pc0 .pricing-card-features {border:0; text-align:left;}
.pc0 .pricing-card-features ul li {font-size:13px; font-weight:normal; display:flex; justify-content: space-between; background:#ffffff; border-bottom:1px solid #ececec; padding-left:0;}
.pc0 .pricing-card-header {visibility:hidden;}
.pc0 .order-btn {visibility:hidden;}

.pricing-card-features ul li.important-info {font-size:22px; font-weight:900;}

.pc1 .pricing-card-header h3, .pc1 .order-btn, .pc1 .important-info {background:#9C9C9C !important; color:#000000;}
.pc2 .pricing-card-header h3, .pc2 .order-btn, .pc2 .important-info {background:#37bdf2 !important; color:#ffffff;}
.pc3 .pricing-card-header h3, .pc3 .order-btn, .pc3 .important-info {background:#1a8ef1 !important; color:#ffffff;}
.pc4 .pricing-card-header h3, .pc4 .order-btn, .pc4 .important-info {background:#009362 !important; color:#ffffff;}
.pc5 .pricing-card-header h3, .pc5 .order-btn, .pc5 .important-info {background:#000000 !important; color:#ffffff;}

.pricing-card-more {max-height:0px; overflow:hidden; transition:.6s}
.pricing-card-more.show {max-height:300px; overflow:visible;}

.step-container {display:flex; flex-direction: row; justify-content: center; align-items: center;}
.step-container .step-nr {margin-right:15px; width:60px; min-width:60px; height:60px; line-height:50px; text-align:center; font-size:44px; font-weight:bold; color:#000000; background:#D9D9D9; border-radius:100%;}


.payment-container {display:flex; flex-direction: row; justify-content: center; align-items: center;}
.payment-option {width:360px; max-width:90%; margin: 0 20px; border:1px solid #d7d7d7; border-radius:8px; box-sizing:border-box; position:relative;}
.payment-option img {max-width:140px; max-height:80px; position:absolute; right:-20px; top:-40px;}
.payment-option h4 {font-weight:bold; padding:30px 30px 15px 30px;}
.payment-option p {padding:0 30px 20px 30px;}
.payment-btn {background:#37bdf2; text-align:center; padding:16px; font-weight:bold; display:block; color:#ffffff; font-size:16px; text-transform:uppercase; border-bottom-left-radius:6px; border-bottom-right-radius:6px;}

.payment-option.po1 img {top: -25px;}

.finalPriceContainer {font-size:22px;}
.finalPriceContainer span.finalPrice {font-size:40px; font-weight:bold;}

.switch{position:relative;display:inline-block;width:460px;height:46px}
.switch input{opacity:0;width:0;height:0}
.slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#fff;border:1px solid #37bdf2;-webkit-transition:.4s;transition:.4s}
.slider:before{position:absolute;content:"";height:40px;width:226px;left:2px;bottom:2px;background-color:#37bdf2;-webkit-transition:.4s;transition:.4s}
input:checked + .slider:before{-webkit-transform:translateX(228px);-ms-transform:translateX(228px);transform:translateX(228px)}
.slider.round{border-radius:8px}
.slider.round:before{border-radius:6px}
.switch-label{display:inline-block;position:relative;z-index:1000;font-size:15px;font-weight:500;color:#37bdf2;width:226px;margin-top:11px}
.switch-label.left{color:#fff}
input:checked + .slider .switch-label.left{color:#37bdf2}
input:checked + .slider .switch-label.right{color:#fff}




.pricing-card-switch {position:absolute; top:0; left:0;}
.pricing-card-switch .switch {width:100%; height:32px;}
.pricing-card-switch .slider:before {height:28px;width:49%;left:1px;bottom:1px;}
.pricing-card-switch input:checked + .slider:before{-webkit-transform:translateX(100%);-ms-transform:translateX(100%);transform:translateX(100%)}
.pricing-card-switch .switch-label {width:49%; font-size:13px; margin-top: 4px; text-align: center;}



.plus-card {border-radius:6px;}

.plus-card-header {display:flex; flex-direction: row; justify-content: center; align-items: center;}
.plus-sum {background:#4E4E4E; border-top-left-radius:6px; width:50%; color:#ffffff; padding:10px 15px;}
.plus-sum em {display:block; margin-bottom:-2px; font-style:normal; font-size:13px;}
.plus-sum strong {font-size:24px; font-weight:bold;}
.plus-sum strong small {font-size:15px; font-weight:normal; text-transform:uppercase;}

.plus-required {background:#1a8ef1; border-top-right-radius:6px; width:50%; color:#ffffff; padding:10px 15px;}
.plus-required em {display:block; margin-bottom:-2px; font-style:normal; font-size:13px;}
.plus-required strong {font-size:24px; font-weight:bold;}
.plus-required strong small {font-size:15px; font-weight:normal; text-transform:uppercase;}

.plus-card-img {position:relative; border-left:1px solid #C6C6C6; border-right:1px solid #C6C6C6; border-top-left-radius:6px; border-top-right-radius:6px;}
.plus-card-img img {width:100%; height:140px; object-fit:cover; border-top-left-radius:6px; border-top-right-radius:6px; display:block;}
.plus-card-img h3 {position: absolute; top: 40px; left: 35px; color: #ffffff; max-width: 65%;}

.plus-card-status {/* position:absolute; left:20px; top:20px; */ font-size:0; margin-bottom:15px;}
.plus-card-status strong {font-weight:700; display:inline-block; padding:4px 12px; color:#ffffff; font-size: 13px; letter-spacing: 0.2px; text-transform:uppercase;}
.plus-card-status em {display:inline-block; padding:4px 12px; color:#ffffff; font-size: 13px; font-weight:bold;}

.plus-card-content {border:1px solid #C6C6C6; border-top:0; border-bottom-right-radius:6px; border-bottom-left-radius:6px; padding:25px 35px; min-height:260px;}
.plus-card-content h3 {margin-bottom:20px;}
.plus-card-content p {}

.plus-btn {color:#ffffff; background:#37bdf2; font-weight:700; text-transform:uppercase; padding:12px 30px; border-radius:6px; display:block; width:fit-content; margin-top:30px; cursor:pointer;}
.plus-btn:disabled {background:#000000; cursor:not-allowed;}


.bg0 {background:#949494;}
.bg1 {background:#37bdf2;}
.bg2 {background:#1a8ef1;}
.bg3 {background:#fb6d18;}
.bg4 {background:#11CA87;}


.limitStatus h5 {padding:5px 12px; border-radius:6px; width:fit-content; color:#ffffff; font-weight:bold; font-size:13px;}
.limitStatus em {color:#37bdf2; font-size:14px;}
.limitStatus em span {font-size:48px; font-weight:bold;}


.hiddenSlick {height: 0px; overflow: hidden; visibility: hidden; transition: height 0.2s linear;}

/* .pricing-card-featured {transform: scale(1.15);} */

/*
.tabset {position:relative; margin-top:20px;}
.tabset > li {position: relative; display: inline-block; padding: 10px 20px; border: 2px solid transparent; border-bottom: 0; cursor: pointer; font-weight: 600; font-size:14px; color:#a1a1b2;}
.tabset > li a {color:#a1a1b2;}

.tabset > li:hover, .tabset > li:hover a {color: #ffffff;}

.tabset > li.active {border-color: #0690f2; border-bottom: 2px solid #16204c; margin-bottom: -2px; background:#16204c; border-top-left-radius: 10px; border-top-right-radius: 10px; color:#0690f2;}
.tabset > li.active a {color:#0690f2;}
*/

/*
 CSS for the main interaction
*/
.tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabset .tab-panel {
  display: none;
}

.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
.tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
.tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
.tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
.tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
  display: block;
}

/*
 Styling
*/

.tabset > label {
  position: relative;
  display: inline-block;
  padding: 12px 25px 20px 25px;
  border: 1px solid transparent;
  border-bottom: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}

.tabset > label::after {
  content: "";
  position: absolute;
  left: 25px;
  bottom: 10px;
  width: 22px;
  height: 4px;
  background: #8d8d8d;
  border-radius:6px;
}

.tabset > label:hover,
.tabset > input:focus + label {
  color: #37bdf2;
}

.tabset > label:hover::after,
.tabset > input:focus + label::after,
.tabset > input:checked + label::after {
  background: #37bdf2;
}

.tabset > input:checked + label {
  border-color: #ccc;
  border-bottom: 1px solid #fff;
  margin-bottom: -1px;
  border-radius:6px;
}

.tab-panel {
  padding: 30px 0;
  border-top: 1px solid #ccc;
}


sup {vertical-align: super; font-size:12px;}



:root{--white:#fff;--black:#000;--bg:#f8f8f8;--grey:#999;--dark:#1a1a1a;--light:#e6e6e6;--blue:#37bdf2}
.chat{position:relative; /* display:none; */ overflow:hidden;padding:0 35px; justify-content:flex-end; flex-direction:column}
.chat.active-chat{display:block;display:flex;}
.chat.active-chat .bubble{transition-timing-function:cubic-bezier(0.4,-0.04,1,1)}
.chat.active-chat .bubble:nth-of-type(1){-webkit-animation-duration:.15s;animation-duration:.15s}
.chat.active-chat .bubble:nth-of-type(2){-webkit-animation-duration:.3s;animation-duration:.3s}
.chat.active-chat .bubble:nth-of-type(3){-webkit-animation-duration:.45s;animation-duration:.45s}
.chat.active-chat .bubble:nth-of-type(4){-webkit-animation-duration:.6s;animation-duration:.6s}
.chat.active-chat .bubble:nth-of-type(5){-webkit-animation-duration:.75s;animation-duration:.75s}
.chat.active-chat .bubble:nth-of-type(6){-webkit-animation-duration:.9s;animation-duration:.9s}
.chat.active-chat .bubble:nth-of-type(7){-webkit-animation-duration:1.05s;animation-duration:1.05s}
.chat.active-chat .bubble:nth-of-type(8){-webkit-animation-duration:1.2s;animation-duration:1.2s}
.chat.active-chat .bubble:nth-of-type(9){-webkit-animation-duration:1.35s;animation-duration:1.35s}
.chat.active-chat .bubble:nth-of-type(10){-webkit-animation-duration:1.5s;animation-duration:1.5s}

.write {height:42px;padding-left:8px; background-color:#eceff1; border-radius:6px; display:flex; margin: 0 35px;}
.write input{font-size:15px; height:38px; background-color:#eceff1; border:0;}
.write span {padding:8px 0; border:0 !important;}
.write .write-link.attach:before{content:""; content: '\f0c6'; font-family: 'FontAwesome'; font-size: 20px; margin-left: 5px; color: #37bdf2; font-weight:normal;}
.write .write-link.send:before{content:""; content: '\f1d8'; font-family: 'FontAwesome'; font-size: 20px; margin-right: 12px; color: #37bdf2; font-weight:normal;}

.bubble{font-size:15px; position:relative; display:inline-block; clear:both; margin-bottom:8px; padding:13px 14px;vertical-align:top; border-radius:5px}
.bubble:before{position:absolute;top:19px;display:block;width:8px;height:6px;content:" ";transform:rotate(29deg) skew(-35deg)}
.bubble.you{float:left;color:var(--white);background-color:var(--blue);align-self:flex-start;}
.bubble.you:before{left:-3px;background-color:var(--blue)}
.bubble.me{float:right;color:var(--dark);background-color:#eceff1;align-self:flex-end;}
.bubble.me:before{right:-3px;background-color:#eceff1}
.conversation-start{position:relative;width:100%;margin-bottom:0px;}
.conversation-start span{font-size:14px; border:0 !important; display:inline-block;color:var(--grey)}






/* common */
.ribbon {width: 150px; height: 150px; overflow: hidden; position: absolute;}
.ribbon::before,
.ribbon::after {position: absolute; z-index: -1; content: ''; display: block; border: 5px solid #bd410d;}
.ribbon span {position: absolute; display: block; width: 225px; padding: 15px 0; background: rgb(218,79,21); background: linear-gradient(177deg, rgba(218,79,21,1) 0%, rgba(161,51,6,1) 100%); box-shadow: 0 5px 10px rgba(0,0,0,.1); color: #fff; font: 600 17px/1 'Poppins', sans-serif; text-shadow: 0 1px 1px rgba(0,0,0,.2); text-transform: uppercase; text-align: center; letter-spacing: 1px;}

/* top left*/
.ribbon-top-left {top: -10px; left: -10px;}
.ribbon-top-left::before,
.ribbon-top-left::after {border-top-color: transparent; border-left-color: transparent;}
.ribbon-top-left::before {top: 0; right: 0;}
.ribbon-top-left::after {bottom: 0; left: 0;}
.ribbon-top-left span {right: -25px; top: 30px; transform: rotate(-45deg);}

/* top right*/
.ribbon-top-right {top: -10px; right: -10px;}
.ribbon-top-right::before,
.ribbon-top-right::after {border-top-color: transparent; border-right-color: transparent;}
.ribbon-top-right::before {top: 0; left: 0;}
.ribbon-top-right::after {bottom: 0; right: 0;}
.ribbon-top-right span {left: -25px; top: 30px; transform: rotate(45deg);}

/* bottom left*/
.ribbon-bottom-left {bottom: -10px; left: -10px;}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {border-bottom-color: transparent; border-left-color: transparent;}
.ribbon-bottom-left::before {bottom: 0; right: 0;}
.ribbon-bottom-left::after {top: 0;left: 0;}
.ribbon-bottom-left span {right: -25px; bottom: 30px; /* transform: rotate(225deg); */ transform: rotate(45deg)}

/* bottom right*/
.ribbon-bottom-right {bottom: -10px; right: -10px;}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {border-bottom-color: transparent; border-right-color: transparent;}
.ribbon-bottom-right::before {bottom: 0; left: 0;}
.ribbon-bottom-right::after {top: 0; right: 0;}
.ribbon-bottom-right span {left: -25px; bottom: 30px; transform: rotate(-225deg);}




p.small, small, span.small {font-size:12px; line-height:16px;}
p.small strong, span.small strong {font-size:13px;}

.infin {font-size:18px !important;}

.choices__inner {box-sizing:border-box !important; min-height: 34px !important; padding: 5px 5px 0px 5px !important;}
.choices__list--multiple .choices__item {padding:2px 5px !important; border-radius:6px !important;}
.choices__list--dropdown {z-index:1001 !important;}
.choices__input.choices__input--cloned {/* visibility:hidden !important; */ background:transparent !important; border:0 !important; padding: 3px 3px !important;}


.toggle-switch-checkbox {
  display: none;
}

.toggle-switch {
  width: 58px;
  height: 28px;
  border-radius: 28px;
  background-color: #000;
  display: inline-block;
  position: relative;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top:16px;
  margin-bottom:6px;
}
.toggle-switch span {display: block; position: absolute; left: 120%; top:5px; text-indent: 0; color: #fff; font-size: 13px;}
.toggle-switch:before, .toggle-switch:after {
  content: "";
  display: block;
  position: absolute;
}
.toggle-switch:before {
  background-color: #0d1122;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 28px;
}
.toggle-switch:after {
  background-color: #6c6c6c;
  width: 22px;
  height: 22px;
  top: 3px;
  left: 3px;
  border-radius: 28px;
  cursor: pointer;
}

.toggle-switch-checkbox:checked + .toggle-switch:before {
 /* background: rgb(135,204,75); background: linear-gradient(177deg, rgba(135,204,75,1) 0%, rgba(89,138,46,1) 100%); */
}
.toggle-switch-checkbox:checked + .toggle-switch:after {
  left: 4em;
  background-color:#0690f2;
}

.toggle-switch-checkbox + .toggle-switch span i {display:block;}
.toggle-switch-checkbox + .toggle-switch span em {display:none;}
.toggle-switch-checkbox:checked + .toggle-switch span i {display:none;}
.toggle-switch-checkbox:checked + .toggle-switch span em {display:block;}

.toggle-switch-checkbox:disabled + .toggle-switch {opacity:0.4;}

.toggle-switch:before {
  -webkit-transition-property: background-color;
  -moz-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  transition-timing-function: ease;
}
.toggle-switch:after {
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 100ms;
  -moz-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.toggle-switch-checkbox:checked + .toggle-switch:after {
  left: inherit;
  -webkit-transform: translateX(32px);
  -moz-transform: translateX(32px);
  -ms-transform: translateX(32px);
  -o-transform: translateX(32px);
  transform: translateX(32px);
}


.border-box {display:block; padding:15px 20px; border:2px solid #37bdf2; border-radius:8px; background-color: whitesmoke;}
.border-box {border-radius: 6px; border-style: solid; border-width: 1px; border-color: #d0cdd3 #c2bfc7 #b3afba; box-shadow: inset 0 0 3px rgba(255,255,255,.76), 0 1px 3px rgba(196,192,200,.85); background-color: #f2f2f4; background-image: -webkit-linear-gradient(top,#fff 0%,#f8f8f9 4%,#ebeaed 90%,#dad6dd); background-image: linear-gradient(to bottom, #fff 0%,#f8f8f9 4%,#ebeaed 90%,#dad6dd); background-size: auto 103%; background-position: center; background-repeat: repeat-x; width: fit-content; display: inline-block; transition:all .3s .12s;}

.border-box h3, .border-box span {transition:all .3s .12s;}

.border-box:active {background-image: -webkit-linear-gradient(top,#fff 0%,#f5f4f8 4%, #e4e2eb 90%, #dad6e0); background-image: linear-gradient(to bottom, #fff 0%, #f5f4f8 4%, #e4e2eb 90%, #dad6e0);}

.border-box:hover { background-image: -webkit-linear-gradient(top, #fff 0%, #e9e8ec 4%, #f7f7f8 90%, #e4e1e6); background-image: linear-gradient(to bottom, #fff 0%, #e9e8ec 4%, #f7f7f8 90%, #e4e1e6);}


/* FAQ */
.faq-header{padding:0 30px 10px 0;}
.faq-header .btn {padding: 8px 12px; font-size: 13px; margin-right: 5px;}
.faq-header .btn.active, .faq-header .btn:hover {background:#37bdf2; color:#ffffff; border:1px solid #37bdf2; cursor:pointer;}

#faqInput{max-width:50%; margin:15px 0}
#faqBtnContainer{margin:15px 0;}

#faq-ul, .faq-ul {list-style-type:none;width:100%;padding:0;background:rgba(255,255,255,0.01); counter-reset: li;}
#faq-ul li span, .faq-ul li span {cursor:pointer;transition:all .35s ease; padding:10px 0; margin:0; text-decoration:none; font-size:16px; display:block; border-top:1px solid #D6D6D6;}
#faq-ul li span:hover:not(.header), .faq-ul li span:hover:not(.header) {/* background-color:#e4ecf9; */ border-top: 1px solid #37bdf2;}
.filterDiv{display:none;transition:all .3s;margin-bottom:4px}
.filterDiv.show{display:block; counter-increment: li;}
.accordion-thumb{font-weight:700;background:#fff}

.filterDiv.is-active .accordion-thumb:after {transform: rotate(45deg); color:#16204c;}
.accordion-thumb:after {content:'';display:inline-block; height:auto; width:auto; float:right; content: '\f0ab'; font-family: 'FontAwesome'; margin-left:30px; vertical-align:middle; -webkit-transform:rotate(-45deg); transform:rotate(-45deg); transition:-webkit-transform .2s ease-out; transition:transform .2s ease-out; margin-right: 10px; font-size: 20px; margin-top: 4px; color:#37bdf2;}

.accordion-thumb:before {content: counter(li, decimal-leading-zero); color:#d7d7d7; font-weight:800; font-size:30px; margin-right:20px; vertical-align: -4px; opacity: .5; min-width: 40px; display: inline-block;}

.accordion-panel{padding:10px 0px;display:none;color:#000;font-size:14px;font-weight:400;background:#fff}





.panel-ul.faq-ul {background:transparent;}
.panel-ul.faq-ul li span {padding:10px 20px; margin:0; font-size:16px; display:block; border:1px solid #37bdf2; background:#37bdf2; border-radius:8px; margin-top:20px; color:#ffffff; font-weight:bold;}
.panel-ul.faq-ul li span:hover:not(.header) {border: 1px solid #37bdf2;}

.panel-ul.faq-ul .filterDiv.is-active .accordion-thumb:after {transform:rotate(180deg); color:#16204c;}
.panel-ul.faq-ul .accordion-thumb:after {content:'';display:inline-block; height:auto; width:auto; float:right; content: '\f0ab'; font-family: 'FontAwesome'; margin-left:30px; vertical-align:middle; transition:-webkit-transform .2s ease-out; transition:transform .2s ease-out; margin-right: 0; font-size: 20px; margin-top: 0; color:#ffffff; transform:rotate(0deg);}

.panel-ul.faq-ul .accordion-thumb:before {display:none;}

.panel-ul.faq-ul .accordion-panel{padding:10px 0px;display:none;color:#000;font-size:14px;font-weight:400;background:#fff}
.panel-ul.faq-ul .filterDiv.is-active .accordion-thumb {background:#ffffff; color:#16204c;}


.font-dark {color:#16204c;}

/* TABLES */
.table{width:100%;margin-bottom:1rem;color:#212529; border-radius:6px; border-collapse: collapse; overflow: hidden; margin-top:20px;}
.table th,.table td{padding:.75rem;vertical-align:top; /* border-top:1px solid #dee2e6 */}
.table th,.table td {border-top:0;}
.table thead th{vertical-align:bottom; background:#37bdf2; color:#ffffff; font-weight:bold; font-size:15px; /* border-bottom:2px solid #dee2e6; */}

.table-bordered th:first-child, .table-bordered td:first-child {border-left:0;}
.table-bordered th:last-child, .table-bordered td:last-child {border-right:0;}

.table img {vertical-align: middle; margin-right:8px; width:24px;}

.table tbody + tbody{/* border-top:2px solid #dee2e6 */ border-bottom-left-radius:6px; border-bottom-right-radius:6px;}
.table-sm th,.table-sm td{padding:.3rem}
.table-bordered{/* border:1px solid #dee2e6 */ border-radius:6px;}
.table-bordered th,.table-bordered td{border:1px solid #dee2e6; vertical-align:middle;}
.table-bordered thead th,.table-bordered thead td{border-bottom-width:2px}
.table-borderless th,.table-borderless td,.table-borderless thead th,.table-borderless tbody + tbody{border:0}
.table-striped tbody tr:nth-of-type(odd){background-color:rgba(0,0,0,0.05)}
.table-hover tbody tr:hover{color:#212529;background-color:rgba(0,0,0,0.075)}
/*
.table-primary,.table-primary > th,.table-primary > td{background-color:#b8daff}
.table-primary th,.table-primary td,.table-primary thead th,.table-primary tbody + tbody{border-color:#7abaff}
.table-hover .table-primary:hover{background-color:#9fcdff}
.table-hover .table-primary:hover > td,.table-hover .table-primary:hover > th{background-color:#9fcdff}
*/
/*
.table-secondary,.table-secondary > th,.table-secondary > td{background-color:#d6d8db}
.table-secondary th,.table-secondary td,.table-secondary thead th,.table-secondary tbody + tbody{border-color:#b3b7bb}
.table-hover .table-secondary:hover{background-color:#c8cbcf}
.table-hover .table-secondary:hover > td,.table-hover .table-secondary:hover > th{background-color:#c8cbcf}
*/
.table-success,.table-success > th,.table-success > td{background-color:#c3e6cb}
.table-success th,.table-success td,.table-success thead th,.table-success tbody + tbody{border-color:#8fd19e}
.table-hover .table-success:hover{background-color:#b1dfbb}
.table-hover .table-success:hover > td,.table-hover .table-success:hover > th{background-color:#b1dfbb}
.table-info,.table-info > th,.table-info > td{background-color:#bee5eb}
.table-info th,.table-info td,.table-info thead th,.table-info tbody + tbody{border-color:#86cfda}
.table-hover .table-info:hover{background-color:#abdde5}
.table-hover .table-info:hover > td,.table-hover .table-info:hover > th{background-color:#abdde5}
.table-warning,.table-warning > th,.table-warning > td{background-color:#ffeeba}
.table-warning th,.table-warning td,.table-warning thead th,.table-warning tbody + tbody{border-color:#ffdf7e}
.table-hover .table-warning:hover{background-color:#ffe8a1}
.table-hover .table-warning:hover > td,.table-hover .table-warning:hover > th{background-color:#ffe8a1}
.table-danger,.table-danger > th,.table-danger > td{background-color:#f5c6cb}
.table-danger th,.table-danger td,.table-danger thead th,.table-danger tbody + tbody{border-color:#ed969e}
.table-hover .table-danger:hover{background-color:#f1b0b7}
.table-hover .table-danger:hover > td,.table-hover .table-danger:hover > th{background-color:#f1b0b7}
.table-light,.table-light > th,.table-light > td{background-color:#fdfdfe}
.table-light th,.table-light td,.table-light thead th,.table-light tbody + tbody{border-color:#fbfcfc}
.table-hover .table-light:hover{background-color:#ececf6}
.table-hover .table-light:hover > td,.table-hover .table-light:hover > th{background-color:#ececf6}
.table-dark,.table-dark > th,.table-dark > td{background-color:#c6c8ca}
.table-dark th,.table-dark td,.table-dark thead th,.table-dark tbody + tbody{border-color:#95999c}
.table-hover .table-dark:hover{background-color:#b9bbbe}
.table-hover .table-dark:hover > td,.table-hover .table-dark:hover > th{background-color:#b9bbbe}
.table-active,.table-active > th,.table-active > td{background-color:rgba(0,0,0,0.075)}
.table-hover .table-active:hover{background-color:rgba(0,0,0,0.075)}
.table-hover .table-active:hover > td,.table-hover .table-active:hover > th{background-color:rgba(0,0,0,0.075)}
.table .thead-dark th{color:#fff;background-color:#0d1122;border-color:#1d2960}
.table .thead-light th{color:#495057;background-color:#e9ecef;border-color:#dee2e6}
.table-dark{color:#fff;background-color:#16204c}
.table-dark th,.table-dark td,.table-dark thead th{border-color:#1d2960}
.table-dark.table-bordered{border:0}
.table-dark.table-striped tbody tr:nth-of-type(odd){background-color:rgba(255,255,255,0.05)}
.table-dark.table-hover tbody tr:hover{color:#fff;background-color:rgba(255,255,255,0.075)}
@media (max-width: 575.98px) {
.table-responsive-sm{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}
.table-responsive-sm > .table-bordered{border:0}
}
@media (max-width: 767.98px) {
.table-responsive-md{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}
.table-responsive-md > .table-bordered{border:0}
}
@media (max-width: 991.98px) {
.table-responsive-lg{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}
.table-responsive-lg > .table-bordered{border:0}
}
@media (max-width: 1199.98px) {
.table-responsive-xl{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}
.table-responsive-xl > .table-bordered{border:0}
}
.table-responsive{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}
.table-responsive > .table-bordered{border:0}
.clearfix:before,.clearfix:after{display:table;content:" "}
.clearfix:after{clear:both}

.table.table-condensed {margin-top:10px; margin-bottom:10px;}
.table.table-condensed td {padding: 3px 10px;}
.table.table-condensed td i {font-size:14px;}

.unactive {opacity:0.5;}



[data-collapse-target] {display:none;}

.formContainer {padding:0; margin-top:17px;}

/*
.formContainer input[type="checkbox"], input[type="checkbox"].checkmark {appearance: none; display: grid; place-content: center; outline:none; width:22px; min-width:22px; height:22px; border:2px solid #16204c; border-radius:6px; float:left; margin-bottom:30px; margin-top:10px;}
.formContainer input[type="checkbox"]:before, input[type="checkbox"].checkmark:before {content: ""; width: 0.60em; height: 0.60em; transform: scale(0); transition: 120ms transform ease-in-out; box-shadow: inset 1em 1em #16204c; border-radius:2px;}
.formContainer input[type="checkbox"]:checked:before, input[type="checkbox"].checkmark:checked:before {transform: scale(1);}
*/

.policyText {font-size:12px; line-height:15px; /* display:block; float:right; width: 90%; */ margin-top: 15px; margin-left: 10px;}
.formContainer .btn {float:right;}
.formContainer textarea {height: 200px;}

input[type="checkbox"] {appearance: none; display: grid; place-content: center; outline:none; min-width:32px; width:32px; height:32px; border:3px solid #757575; border-radius:8px; margin-right:5px; cursor:pointer; float:left; margin-bottom:30px; margin-top:10px; margin-left:0;}
input[type="checkbox"]:before {content: ""; transform: scale(0); transition: 120ms transform ease-in-out; color:#009362; /* box-shadow: inset 16px 16px #03738c; */ border-radius:4px; width: 18px; height: 18px; content: "\f00c"; font-family: "Font Awesome 5 Free"; font-weight: 900; text-align: center; font-size: 16px;}
input[type="checkbox"]:checked:before {transform: scale(1);}

input[type="checkbox"]:disabled {border:3px solid #737373;}
input[type="checkbox"]:disabled:before {/* box-shadow: inset 16px 16px #737373; */ color:#737373;}

.dark-bg input[type="checkbox"].checkmark {border:3px solid #ffffff;}
.dark-bg input[type="checkbox"].checkmark:before {/* box-shadow: inset 1em 1em #ffffff; */ color:#ffffff;}

.loginContainer {max-width:600px; margin:30px auto 60px auto; border:1px solid #d5d5d5; border-radius:8px; padding:20px 30px; box-sizing:border-box;}

.boxContainer {margin-top:15px; border:1px solid #d5d5d5; border-radius:8px; padding:20px 30px; box-sizing:border-box; height:100%; background: #dbdbdb;}
.hzdll {background: #E6E6E6;}


.hzdll {display:block; border:1px solid #d5d5d5; border-radius:8px; padding:10px 15px; box-sizing:border-box; text-align:center; box-shadow:1px 1px 6px rgba(0,0,0,0.06);}
.hzdll strong {font-size:16px; font-weight:bold; display:block; text-align:center; margin-bottom:12px;}
.hzdll em {font-size:16px; font-weight:normal; color:#11CA87;}
.hzdll em span {font-size:36px; font-weight:bold;}



dl {display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); gap: 0em 1em; width: fit-content; margin: 0 auto; margin-top:15px;}
dt {/* display: contents; */ text-align: right;}
dt span:after {margin: 0; padding: 0; box-sizing: border-box; content: ':';}


.packages .hzdll strong {font-size:15px;}
.packages .hzdll img {width:42px; height:42px; object-fit:contain; display: block; margin: 10px auto;}
.packages .hzdll em {font-size:13px; font-weight:bold; color:#ffffff; border-radius:6px; padding:4px 10px;}
.packages .hzdll i {font-style:normal; font-size:12px; font-weight:bold; display:block; margin-top:10px;}
.packages .hzdll i small {font-size:12px; font-weight: normal;}
.packages .hzdll i u {font-size:32px; text-decoration:none;}

.hzdll.special {background:#16204c;}
.hzdll.special strong, .hzdll.special i, .hzdll.special i * {color:#ffffff;}
				

.filesReady td {line-height:16px; font-size:15px; word-wrap:wrap; overflow-wrap:anywhere;}		
.filesStatus {padding:10px 22px; color:#ffffff; border-radius:6px; white-space:nowrap; font-size: 15px;}	
.filesReady .btn {display:inline-block;}	

.styled-checkbox, .multiselect-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label, .multiselect-checkbox + span {
  position: relative;
  cursor: pointer;
  padding: 0;
  font-size:15px; line-height:45px;
}
.styled-checkbox + label:before, .multiselect-checkbox + span:before {
  content: '';
  margin-right: 16px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  border:2px solid #0166b5;
  border-radius:8px;
  margin-top: -2px;
  
}
.styled-checkbox:hover + label:before, .multiselect-checkbox:hover + span:before {
  background: #334180;
}
.styled-checkbox:focus + label:before, .multiselect-checkbox:focus + span:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before, .multiselect-checkbox:checked + span:before {
  background: #334180;
}
.styled-checkbox:disabled + label, .multiselect-checkbox:disabled + span {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before, .multiselect-checkbox:disabled + span:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after, .multiselect-checkbox:checked + span:after {
position:absolute; left:6px; top:3px; font-size:11px; display: inline-block; font-style: normal; font-variant: normal; text-rendering: auto; -webkit-font-smoothing: antialiased; font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f00c"; color:#ffffff; line-height: 16px;
}

.multiselect-checkbox:checked + span:after {top:1px; left:7px;}







.dropdown-parent {position:relative; padding-top:12px; padding-bottom:12px;}
.dropdown-parent:not(.noArrow):after {position:absolute; right:10px; top:20px; font-size:11px; display: inline-block; font-style: normal; font-variant: normal; text-rendering: auto; -webkit-font-smoothing: antialiased; font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f078"; color:#ffffff; line-height: 16px;}
.dropdown-child {position: absolute; top:100%; left:0; z-index:100; background-color: #0d1122; width: 230px; border-radius:20px;}
.dropdown-child.left {width:100%;}

.dropdown-parent .dropdown-child:after {
	display:block; top:-7px; left:30px; content:" "; position:absolute;
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  
  border-bottom: 7px solid black;
}

.dropdown-parent.left:after {display:none;}

.dropdown-child {max-height:0px; overflow:hidden; padding:0px 10px; box-sizing:border-box;}
.dropdown-child a {color:#ffffff; font-size:14px; display:block; padding:6px 10px;}
.dropdown-child li {}
.dropdown-child li a:hover {background:#202d61; border-radius:12px;}
.dropdown-child li:last-child {border-bottom:0; box-shadow:none;}
.dropdown-child i {margin-right:6px;}

.dropdown-parent:hover .dropdown-child{display: block; max-height:500px; padding:12px 10px; /* overflow:visible; */}





.progress-bar.animated { 
    -webkit-animation-duration: 12s; 
    animation-duration: 12s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
    -webkit-animation-timing-function: linear; 
    animation-timing-function: linear; 
    animation-iteration-count:infinite; 
    -webkit-animation-iteration-count:infinite; 
    -webkit-animation-name: shake; 
    animation-name: shake; 
	display:inline-block;
} 

@-webkit-keyframes shake { 
    0%, 100% {-webkit-transform: translateX(0);} 
    10%, 30%, 50%, 70%, 90% {-webkit-transform: translateX(-80px);} 
    20%, 40%, 60%, 80% {-webkit-transform: translateX(80px);} 
} 
@keyframes shake { 
    0%, 100% {transform: translateX(0);} 
    10%, 30%, 50%, 70%, 90% {transform: translateX(-80px);} 
    20%, 40%, 60%, 80% {transform: translateX(80px);} 
} 




.search-table.panelSearch.filesReady .search-table-name {text-overflow: ellipsis; overflow: visible;}


.select-wrapper {position: relative; /* width: 100%; */ display:inline-block;}
.select-wrapper:after, .multiselect-dropdown-arrow {content: ''; pointer-events: none; width: 0;height: 0;border-left: 7px solid transparent;border-right: 7px solid transparent;border-top: 7px solid #cccccc;position: absolute;display: inline-block !important;right:15px; top:20px;}

.select-wrapper select {-moz-appearance: none; -webkit-appearance: none;}
.select-wrapper select:focus {}
.select-wrapper select:-ms-expand {display: none;}

.orderTable td {vertical-align:middle;}

.select-wrapper.small {text-align:center; margin-top: 4px;}
.select-wrapper.small select {padding: 6px 10px; font-size:13px; text-align:center;}
.select-wrapper.small:after {display:none !important;}
.select-wrapper.small option {text-align:center;}


.status-text {margin:-10px 0 10px 0;}




.bottomMenu {position: fixed; bottom:0; left: 0; right: 0; background:#1d295a; opacity:0.95; max-height:0; overflow:hidden; padding-bottom:0; transition:all .3s .12s;}
.bottomMenu.show {max-height:800px; z-index:5000; padding-bottom:60px; padding-top:20px; overflow:auto;}
.bottomMenuBtn {background:#1d295a; height:12px; position:fixed; left:0; right:0; bottom:0; opacity:0.95; cursor:pointer; z-index:5001; transition:all .3s .12s;}
.bottomMenuBtn:after {content:" "; display:block; position:absolute; width:120px; height:120px; border-radius:100%; left:50%; margin-left:-65px; top:-46px; background:#1d295a; text-align:center; color:#ffffff; box-sizing:border-box; padding-top:14px; transition:all .3s .12s; bottom: 0;}
.bottomMenuBtn:before {content:" "; display:block; position:absolute; left:50%; margin-left:-12px; z-index: 1; font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f102"; color:#ffffff; font-size:28px; transition:all .3s .12s;}

.bottomMenuBtn:not(.active):before {animation: MoveUpDown 1s linear infinite;}

.bottomMenuBtn.active:after {background:#1d295a;}
.bottomMenuBtn.active:before {content: "\f103"; bottom:12px;}


@keyframes MoveUpDown {
  0%, 100% {
    bottom: 12px;
  }
  50% {
    bottom: 20px;
  }
}


.admin-your-account .fieldset-alike h3 strong {/* background: #38a7f7; padding: 3px 10px; border-radius: 10px; */ margin-bottom: 5px; display: inline-block; color:#ef5d01; font-size:28px;}

.text-center {text-align:center;}




@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 2px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 18px;
  width: 18px;
  transition: all 0.15s ease-out 0s;
  background: #ffffff;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover {
  background: #9faab7;
}
.option-input:checked {
  background: #81c347;
}
.option-input:checked:before {
  height: 18px;
  width: 18px;
  position: absolute;
  content: '✔';
  display: inline-block;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  color:#ffffff;
}
.option-input:checked:after {
/*
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #81c347;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
*/
}
.option-input.radio {
  border-radius: 50%;
}
.option-input.radio::after {
  border-radius: 50%;
}

.search-table-container .search-table a.btn.btn-primary.btn-small {white-space:nowrap;}












/* Spacing helpers */

.mt-0{margin-top:0!important}
.mt-5{margin-top:5px!important}
.mt-10{margin-top:10px!important}
.mt-15{margin-top:15px!important}
.mt-20{margin-top:20px!important}
.mt-25{margin-top:25px!important}
.mt-30{margin-top:30px!important}
.mt-40{margin-top:40px!important}
.mt-50{margin-top:50px!important}
.mt-60{margin-top:60px!important}
.mt-80{margin-top:80px!important}
.mb-0{margin-bottom:0!important}
.mb-5{margin-bottom:5px!important}
.mb-10{margin-bottom:10px!important}
.mb-15{margin-bottom:15px!important}
.mb-20{margin-bottom:20px!important}
.mb-25{margin-bottom:25px!important}
.mb-30{margin-bottom:30px!important}
.mb-40{margin-bottom:40px!important}
.mb-50{margin-bottom:50px!important}
.mb-60{margin-bottom:60px!important}
.mb-80{margin-bottom:80px!important}
.pt-0{padding-top:0!important}
.pt-5{padding-top:5px!important}
.pt-10{padding-top:10px!important}
.pt-15{padding-top:15px!important}
.pt-20{padding-top:20px!important}
.pt-25{padding-top:25px!important}
.pt-30{padding-top:30px!important}
.pt-40{padding-top:40px!important}
.pt-50{padding-top:50px!important}
.pt-60{padding-top:60px!important}
.pt-80{padding-top:80px!important}
.pt-90{padding-top:90px!important}
.pt-100{padding-top:100px!important}
.pb-0{padding-bottom:0!important}
.pb-5{padding-bottom:5px!important}
.pb-10{padding-bottom:10px!important}
.pb-15{padding-bottom:15px!important}
.pb-20{padding-bottom:20px!important}
.pb-25{padding-bottom:25px!important}
.pb-30{padding-bottom:30px!important}
.pb-40{padding-bottom:40px!important}
.pb-50{padding-bottom:50px!important}
.pb-60{padding-bottom:60px!important}
.pb-80{padding-bottom:80px!important}
.pb-90{padding-bottom:90px!important}
.pb-100{padding-bottom:100px!important}







.alert {
  display: flex;
  width: 100%;
  justify-content: center;
}

.toast-title{font-weight:700; font-size: 15px;}
.toast-message{-ms-word-wrap:break-word;word-wrap:break-word}
.toast-message a,.toast-message label{color:#2d2d2d}
.toast-message a:hover{color:#CCC;text-decoration:none}

.toast-close-button{position:relative;right:-.3em;top:-.3em;float:right;font-size:20px;font-weight:700;color:#6b727c; line-height:1}

.toast-close-button:focus,.toast-close-button:hover{color:#000;text-decoration:none;cursor:pointer;opacity:.4;-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=40);filter:alpha(opacity=40)}

button.toast-close-button{padding:0;cursor:pointer;background:0 0;border:0;-webkit-appearance:none}.toast-top-center{top:0;right:0;width:100%}.toast-bottom-center{bottom:0;right:0;width:100%}.toast-top-full-width{top:0;right:0;width:100%}.toast-bottom-full-width{bottom:0;right:0;width:100%}.toast-top-left{top:30px;left:30px}.toast-top-right{top:30px;right:30px}.toast-bottom-right{right:30px;bottom:30px}.toast-bottom-left{bottom:30px;left:30px}#toast-container-center{position:fixed;z-index:999999;pointer-events:none}

#toast-container-center *{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box}

#toast-container-center>div{position:relative;pointer-events:auto;overflow:hidden;margin:0 0 6px;padding:15px 15px 15px 60px;width:350px; border-radius:8px; color:#2d2d2d; opacity:1; box-shadow:none; box-shadow:0 0 12px rgba(0,0,0,0.3);cursor:pointer;}

#toast-container-center>div:hover{}

#toast-container-center>.toast-info{border:2px solid #b4cdef; background-color:#e7effa;}
#toast-container-center>.toast-error{border:2px solid #efd0c7; background-color:#fbefeb;}
#toast-container-center>.toast-success{border:2px solid #cfe8d3; background-color:#f1f8f4;}
#toast-container-center>.toast-warning{border:2px solid #f5e0b8; background-color:#fef8eb;}

#toast-container-center.toast-bottom-center>div,#toast-container-center.toast-top-center>div{width:300px;margin-left:auto;margin-right:auto}#toast-container-center.toast-bottom-full-width>div,#toast-container-center.toast-top-full-width>div{width:96%;margin-left:auto;margin-right:auto}

.toast{background-color:#030303; line-height: 16px;}

.toast-progress{position:absolute;left:0;bottom:0;height:4px;background-color:#000;opacity:.4;-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=40);filter:alpha(opacity=40)}

@media all and (max-width:240px){#toast-container-center>div{padding:8px 8px 8px 50px;width:11em}
#toast-container-center .toast-close-button{right:-.2em;top:-.2em}}
@media all and (min-width:241px) and (max-width:480px){#toast-container-center>div{padding:8px 8px 8px 50px;width:18em}
#toast-container-center .toast-close-button{right:-.2em;top:-.2em}}
@media all and (min-width:481px) and (max-width:768px){#toast-container-center>div{padding:15px 15px 15px 50px;width:25em}}


#toast-container-center > .toast {
    background-image: none !important;
}

#toast-container-center > .toast:before, .xplus-status li:before, div.xplus-status:before {
    position: fixed;
    font-family: FontAwesome;
    font-size: 18px;
    line-height: 1;
	text-align:center;
    float: left;
    color: #FFF;
    margin: auto 10px auto -44px;
	width: 32px;
	height: 32px;
	border-radius: 100%;
	
display: flex;
justify-content: center;
align-items: center;
}    

div.xplus-status:before {font-size: 12px; width: 22px; height: 22px; margin-left: 0;}
div.xplus-status {display:inline-block;}
.xplus-status.static li:before, div.xplus-status:before {position:static;}
.xplus-status.static li.disabled:before {background-color:#ddd;}
#toast-container-center > .toast-warning:before, .xplus-status-warning:before {
    content: "\f06a";
	background-color: #fec022;
}
#toast-container-center > .toast-error:before, .xplus-status-error:before {
    content: "\f057";
	background-color: #fc5758;
}
#toast-container-center > .toast-info:before, .xplus-status-info:before {
    content: "\f05a";
	background-color: #3186ea;
}
#toast-container-center > .toast-success:before, .xplus-status-success:before {
    content: "\f00c";
	background-color: #50dc6c;
}

#toast-container{position:fixed;z-index:999999;pointer-events:none}

#toast-container *{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box}

#toast-container>div{position:relative;pointer-events:auto;overflow:hidden;margin:0 0 6px;padding:15px 15px 15px 60px;width:350px; border-radius:8px; color:#2d2d2d; opacity:1; box-shadow:none; box-shadow:0 0 12px rgba(0,0,0,0.3);cursor:pointer;}

#toast-container>div:hover{}

#toast-container>.toast-info{border:2px solid #b4cdef; background-color:#e7effa;}
#toast-container>.toast-error{border:2px solid #efd0c7; background-color:#fbefeb;}
#toast-container>.toast-success{border:2px solid #cfe8d3; background-color:#f1f8f4;}
#toast-container>.toast-warning{border:2px solid #f5e0b8; background-color:#fef8eb;}

#toast-container.toast-bottom-center>div,#toast-container.toast-top-center>div{width:300px;margin-left:auto;margin-right:auto}#toast-container.toast-bottom-full-width>div,#toast-container.toast-top-full-width>div{width:96%;margin-left:auto;margin-right:auto}

.toast{background-color:#030303; line-height: 16px;}

.toast-progress{position:absolute;left:0;bottom:0;height:4px;background-color:#000;opacity:.4;-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=40);filter:alpha(opacity=40)}

@media all and (max-width:240px){#toast-container>div{padding:8px 8px 8px 50px;width:11em}
#toast-container .toast-close-button{right:-.2em;top:-.2em}}
@media all and (min-width:241px) and (max-width:480px){#toast-container>div{padding:8px 8px 8px 50px;width:18em}
#toast-container .toast-close-button{right:-.2em;top:-.2em}}
@media all and (min-width:481px) and (max-width:768px){#toast-container>div{padding:15px 15px 15px 50px;width:25em}}


#toast-container > .toast {
    background-image: none !important;
}

#toast-container > .toast:before, .xplus-status li:before, div.xplus-status:before {
    position: fixed;
    font-family: FontAwesome;
    font-size: 18px;
    line-height: 1;
	text-align:center;
    float: left;
    color: #FFF;
    margin: auto 10px auto -44px;
	width: 32px;
	height: 32px;
	border-radius: 100%;
	
display: flex;
justify-content: center;
align-items: center;
}    

div.xplus-status:before {font-size: 12px; width: 22px; height: 22px; margin-left: 0;}
div.xplus-status {display:inline-block;}
.xplus-status.static li:before, div.xplus-status:before {position:static;}
.xplus-status.static li.disabled:before {background-color:#ddd;}
#toast-container > .toast-warning:before, .xplus-status-warning:before {
    content: "\f06a";
	background-color: #fec022;
}
#toast-container > .toast-error:before, .xplus-status-error:before {
    content: "\f057";
	background-color: #fc5758;
}
#toast-container > .toast-info:before, .xplus-status-info:before {
    content: "\f05a";
	background-color: #3186ea;
}
#toast-container > .toast-success:before, .xplus-status-success:before {
    content: "\f00c";
	background-color: #50dc6c;
}




.loading {
  font-size: 84px;
  margin-top:70px;
  margin-bottom:40px;
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  text-align: center;
  letter-spacing: 12px;
}
.loading h3 {margin-top:40px;}
.loading span {
  display: inline-block;
  margin: 0 -0.05em;
}

.loading06 span {
  position: relative;
  color: rgba(0, 0, 0, 0.2);
}
.loading06 span::after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  color: #fff;
  opacity: 0;
  transform: rotateY(-90deg);
  animation: loading06 4s infinite;
}
.loading06 span:nth-child(2)::after {
  animation-delay: 0.2s;
}
.loading06 span:nth-child(3)::after {
  animation-delay: 0.4s;
}
.loading06 span:nth-child(4)::after {
  animation-delay: 0.6s;
}
.loading06 span:nth-child(5)::after {
  animation-delay: 0.8s;
}
.loading06 span:nth-child(6)::after {
  animation-delay: 1s;
}
.loading06 span:nth-child(7)::after {
  animation-delay: 1.2s;
}
.loading06 span:nth-child(8)::after {
  animation-delay: 1.4s;
}
.loading06 span:nth-child(9)::after {
  animation-delay: 1.6s;
}

@keyframes loading06 {
  0%, 75%, 100% {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  25%, 50% {
    transform: rotateY(0);
    opacity: 1;
  }
}





.loading.loading04 {
  font-size: 12px;
  margin-top:10px;
  margin-bottom:10px;
  font-weight: 800;
  text-align: center;
  letter-spacing: 2px;
}

.loading04 span {
  animation: loading04 0.7s infinite;
}

.loading04 span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading04 span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading04 span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading04 span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading04 span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading04 span:nth-child(7) {
  animation-delay: 0.6s;
}
.loading04 span:nth-child(8) {
  animation-delay: 0.7s;
}
.loading04 span:nth-child(9) {
  animation-delay: 0.8s;
}
.loading04 span:nth-child(10) {
  animation-delay: 0.9s;
}
.loading04 span:nth-child(11) {
  animation-delay: 1.0s;
}
.loading04 span:nth-child(12) {
  animation-delay: 1.1s;
}
.loading04 span:nth-child(13) {
  animation-delay: 1.2s;
}
.loading04 span:nth-child(14) {
  animation-delay: 1.3s;
}

@keyframes loading04 {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3px);
  }
}

.mOnly {display:none;}

.box-ico {width:100%; height:140px; border-top-left-radius:6px; border-top-right-radius:6px; background-color: #16204c; margin:0; padding-top:30px; box-sizing:border-box;}
.box-ico img, .plus-card-img .box-ico img {max-height:100%; max-height: 70%; height:auto; width:auto; margin: 0px 30px 0 auto; display: block; object-fit:cover;}

.box-ico1 {background:#16204c; background: linear-gradient(351deg, rgba(22,32,76,1) 0%, rgba(61,80,163,1) 100%); color:#000000;}
.box-ico2 {background:#37bdf2; background: linear-gradient(351deg, rgba(24,127,167,1) 0%, rgba(55,189,242,1) 100%); color:#ffffff;}
.box-ico3 {background:#1a8ef1; background: linear-gradient(351deg, rgba(23,92,150,1) 0%, rgba(26,142,241,1) 100%); color:#ffffff;}
.box-ico4 {background:#009362; background: linear-gradient(351deg, rgb(16, 132, 93) 0%, rgb(55, 183, 140) 100%); color:#ffffff;}
.box-ico5 {background:#fb6d18; background: linear-gradient(351deg, rgba(205,77,0,1) 0%, rgba(251,109,24,1) 100%); color:#ffffff;}



@media only screen and (max-width: 1024px) {
	
	.pc0 .pricing-card-features {display:none;}
	.pc0 .pricing-card-header p {display:none;}
	
	.mOnly {display:block;}
	.label.mOnly {font-size: 11px; font-weight: normal;}
	
}

/* md - 75em */
@media only screen and (max-width: 75em) {
	
.header .btn {margin-left: 12px;padding: 4px 6px;font-size: 11px;}

	.menu {position:relative;}
	
	.navbar-main {position: absolute; width: 100%; left: 0; right: 0; top:0;}
	.nav-toggle {position: absolute; right: 0; top: 0;}
	
	.navbar-main li {display:block; text-align:center;}
	.navbar-main > li > a, .navbar-main > li:last-child a {height:30px; line-height:30px; margin:0px; color:#ffffff; border:0;}
	.navbar-main li a:hover, .navbar-main li.active a, .navbar-main > li.selected > a {text-decoration:underline; color:#ffffff; border:0;}
	.navbar-main > li:last-child a {margin:0; background:transparent; padding-left:0; padding-right:0;}
	.navbar-main > li:last-child i {display:none !important;}
	
	.navbar-main ul {display:none; position:static; top:auto; left:auto; text-align:center; padding:4px 0; border-top:0;}
	.navbar-main ul li {width:100%; height:auto; padding:0;}
	.navbar-main ul li a {padding:10px 18px;}
	.navbar-main li:hover > ul, .navbar-main li.selected > ul, .navbar-main li.current-menu-parent > ul {display:block !important;}

	.navbar-main ul ul {display:none; position:static; top:auto; left:auto; background:#031674; padding:0; z-index:100; text-align:center; padding:4px 0;}
	.navbar-main ul li:hover > ul, .navbar-main ul li.selected > ul, .navbar-main ul li.current-menu-parent > ul {display:block}
	
	.navbar-main {max-height: 0; height:auto; overflow: hidden; transition: all 0.5s ease; text-align:center; padding:0;}
	.navbar-main.responsive {max-height:1000px; z-index: 1000; background:#1a181e; padding: 20px 0;}
	
    .nav-toggle {
        position: absolute;
        float: right;
        z-index: 1000;
        display: inline-block;
        width: 40px;
        height: 30px;
        cursor: pointer;
        top: 20px;
        right: 30px;
    }

    .line1, .line2, .line3 {
        background: #fff;
        width: 40px;
        height: 5px;
        position: absolute;
        transition: all 0.5s ease;
		border-radius:2px;
    }

    .line1 {top: 0;left: 0;transform: rotate(0);}
    .line2 {top: 10px;left: 0;transform: rotate(0);opacity: 1;}
    .line3 {top: 20px;left: 0;transform: rotate(0);}
	
    .nav-toggle.responsive .line1 {transform:rotate(45deg); top: 10px; background:#fff;}
    .nav-toggle.responsive .line2 {opacity: 0; background:#fff;}
    .nav-toggle.responsive .line3 {transform:rotate(-45deg);top: 10px; background:#fff;}

	.header .flexbox {margin-top:12px;}
	
	.limitStatus {display:block; margin-top:10px;}
}

/* sm - 62em */
@media only screen and (max-width: 62em) {
	
	

}

/* xs - 48em */
@media only screen and (max-width: 48em) {
	
	.msq {margin-left:-20px;}
	.pricing-card-more.show {max-height:420px;}
	
	.qMenu > ul > li > div > a {font-size: 0; width: 70px; height: 60px;}
	.step-container .step-nr {margin-right:10px; width:30px; min-width:30px; height:30px; line-height:30px; font-size:14px;}
	
	.switch{width:290px;height:36px;}
	.slider:before{height:30px;width:141px;}
	input:checked + .slider:before{-webkit-transform:translateX(143px);-ms-transform:translateX(143px);transform:translateX(143px)}
	.switch-label{font-size:11px;width:141px;margin-top:6px}
	
	.payment-container {flex-direction: column;}
	.payment-option {margin-top:30px;}

	.hero form {width:100%;}
	
	.box {margin-top:20px;}

}


.slick-slide > div:first-child {
  margin: 0 10px;
}

/* the parent */
.slick-list {
  margin: 0 -10px;
}

.ReactCollapse--collapse {
  transition: height 600ms;
}

.packages-slider .slick-list{
  padding: 0 !important;
}


.xplus-status li {
  margin: 5px 0;
  margin-left: 25px;
}


.qMenu {position: fixed;z-index: 2; right: 0; top: 25%; font-size: 11px; text-align: center; font-weight: 500; display: block !important;}

.qMenu .qMenu1 > div > a {border-right: 4px solid #18A0FB; color: #18A0FB;}
.qMenu .qMenu2 > div > a {border-right: 4px solid #16204C; color: #16204C;}
.qMenu .qMenu3 > div > a {border-right: 4px solid #ff6600; color: #ff6600;}

.qMenu > ul > li {transition: all 0.8s ease; display: block; transition: width .25s; right: 0; margin: 0px; position:absolute; right:-250px; top:0; height: 90px;}
.qMenu > ul > li.qMenu2 {top:102px;}
.qMenu > ul > li > div > a {width:100%; width: 120px; padding: 10px 15px; box-sizing:border-box; display:block; font-size: 14px; line-height: 18px; border: 1px solid rgba(0, 0, 0, .15); border-radius: 6px 0px 0px 6px; background-color: rgba(255, 255, 255, 1); border-right: 4px solid; height: 96px;}
.qMenu > ul > li > div > a img {width:36px;}
.qMenu .qMenu2 a {color: #16204C;}


.pull-out-content {transition: all 0.5s ease; box-sizing:border-box; padding:20px 30px; width: 250px; background:#ffffff; border: 1px solid rgba(0, 0, 0, .15); margin-top:-30px; border-top-left-radius:8px; border-bottom-left-radius:8px; font-size: 13px; text-align:left;}
.qMenu > ul > li:hover {transition: all 0.5s ease; right:0px; z-index:10;}
.pull-out-tab {height:90px;}
.qMenu > ul > li:hover .pull-out-tab {height:auto;}

.xplus-status li:before {width: 22px; height: 22px; font-size:12px; margin-left:-30px;}
.xplus-status li {margin:5px 0; margin-left:25px;}

.qMenu2 .pull-out-content {padding:0px 10px;}

@media only screen and (max-width: 48em) {
	
	.qMenu > ul > li > div > a {font-size: 0; width: 70px; height: 60px;}
	.qMenu > ul > li {height:60px;}
	.pull-out-tab {height:60px;}
	.qMenu > ul > li.qMenu2 {top:72px;}
	
}

.flex-1-1 {
  flex: 1 1;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.btns li:hover {
    cursor: pointer;
}

.m-auto {
  margin: auto;
}

.cookie__btn {
  background: #16204c !important;
  color: #ffffff !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  border-radius: 5px !important;
  width: 116px !important;
  margin: 0 28px !important;
}

.cookie {
  opacity: 1 !important;
  max-width: 24em !important;
  background: #0d1122 !important;
  color: #ffffff !important;  
  margin-bottom: 1em !important;
  margin-left: 1em !important;
  border-radius: 5px !important;
  padding: 1.2em !important;
  font-size: 16px !important;
  line-height: 1.5em !important;
}

.cookie__content {
  margin: 0 !important;
  margin-bottom: 1em !important;
}

.cookie__content__text {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
}

@media screen and (max-width: 440px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cookie {
      margin: 0 !important;
  }
}

.MuiInputBase-input {
  border: none !important;
}

.MuiFormLabel-root {
  font-family: 'Raleway', sans-serif !important; 
  font-size:14px !important; 
  /* color:#a1a1b2 !important;  */
}

.MuiChip-label {
  font-family: 'Raleway', sans-serif !important; 
}

/** Sterowanie wierszami w tabeli na dashboardzie **/

/* table.db-table-links td { */
  /* height: 90px; */
  /* vertical-align: top;
  padding-top: 10px;
}

table.db-table-links .filesStatus {
  display: block;
}

table.db-table-links button {
  margin-top: 0px;
} */